import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: absolute;
    bottom: 0;
    margin: 0;
    height: auto;
    width: 100%;
    background-color: black;
    color: white;
    @media(max-width: 800px) {
        font-size: 16px;
        height: 15px;
    }
`;


const Footer = () => {
    return (
        <Container>
            <footer>
				<p style={{ textAlign: "center" }}>Copyright &copy; 2021 Sognare Hair Boutique, Inc. All Rights Reserved. <a href='https://www.fleurtechnologies.com' style={{ textDecoration: 'none', color: 'rgb(131, 51, 171)' }}> | Powered by Fleur Technologies </a></p>
			</footer>
        </Container>
    );
}

export default Footer;