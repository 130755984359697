import React from 'react';
import styled from 'styled-components';
import Logo from '../images/sognarewhitewhite.png';
import Background from '../images/IMG_2162.jpeg';
import Footer from '../components/footer';

import Facebook from '../images/facebook.svg';
import Instagram from '../images/instagram.svg';
import Image from '../images/moroccan_oil.png';
import PriceList from '../images/sognare_price_list.pdf';

const Container = styled.div`
	width: 100%;
	height: 100%;
	min-width: 100vw;
	min-height: 100vh;
	/* background-image: url(${Background});
	background-image: cover; */
	background-color: #3C3C3C;
	display: flex;
	flex-direction: column;
	align-items: center;
	.pageOne {
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		.callButton {
			position: absolute;
			top: 15px;
			right: 15px;
			padding: 15px;
			background-color: white;
			border-radius: 15px;
			a {
				text-decoration: none;
				color: black;
			}
		}
		h1 {
			color: white;
			font-size: 3.5rem;
			font-weight: bold;
			@media(max-width: 800px) {
				font-size: 1.5rem;
				text-align: center;
				margin: 0 auto;
			}
		}
		h2 {
			font-size: 2.75rem;
		}
		.image {
			width: 40%;
			@media(max-width: 800px) {
				width: 85%;
				padding: 100px 0 0 0;
				
			height: 400px;
			}

		}
		.socialIcons {
			position: absolute;
			display: flex;
			flex-direction: row;
			align-items: space-between;
			top: 15px;
			left: 15px;
			padding: 15px;
			background-color: white;
			border-radius: 15px;
			a {
				text-decoration: none;
				color: black;
				margin: 0 5px 0 5px;
			}
		}
	}
	.pageTwo, .pageThree {
		width: 100%;
		height: 100vh;
		background: lightblue;
		display: flex;
		flex-direction: row;
		align-items: center;
		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 50%;
			h3 {
				color: black;
				font-size: 3.5rem;
			}
			p, h3, a {
				width: 75%;
				text-align: left;
			}
			a {
				font-size: 2.25rem;
				text-decoration: none;
			}
		}
		.image {
			width: 50%;
			img {
				width: 85%;
				max-height: 75%;
			}
		}
	}
	.pageThree {
		background-color: #3C3C3C;
		.content {
			h3 {
				color: white;
			}
		}
		.image {
			img {
				max-height: 85vh;
			}
		}
	}
	footer {
	    bottom: 0;
	    margin: 0;
	    height: auto;
	    width: 100%;
	    background-color: #3C3C3C;
	    color: white;
	    @media(max-width: 800px) {
	        font-size: 16px;
	        height: 15px;
	    }
	}
	
`;

const Home = () => {
	return (
        <Container>
			<div className='pageOne'>
				<div className='socialIcons'>
				<a href='https://www.facebook.com/sognarehairboutique/'><img style={{ height: '35px', width: '35px' }} alt="Facebook" src={Facebook} /></a><a style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItem: 'center', justifyContent: 'center'}}href='https://www.instagram.com/sognarehairboutique/'><img style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItem: 'center', justifyContent: 'center', height: '35px', width: '35px' }} alt="Instagram" src={Instagram} /></a>
				</div>
				<div className='callButton'>
					<a href='tel:+18479842848'>Call to Schedule Appointment</a>
				</div>
	        	<img className='image' src={Logo} alt="Sognare"/>
	        	<h1>We're Open!</h1>
	        	<h2><a style={{color: 'lightblue', textDecoration: 'none'}} href='https://app.salonrunner.com/customer/login.htm?id=42410'>Click to Schedule an Appointment Online</a></h2>
			</div>
        	<div className='pageTwo'>
        		<div className='content'>
        			<h3>Shop Online with Sognare!</h3>
        			<a href='https://shop.saloninteractive.com/store/sognare'>Click here to buy</a>

        		</div>
        		<div className='image'>
        			<img src={Image} alt="Online Shopping" />
        		</div>
        	</div>
			<div className='pageThree'>
				<div className='content'>
					<h3>Services &amp; Prices</h3>
				</div>
				<div className='image'>
					<img src={PriceList} alt="Price List" /> 
				</div>
			</div>
			<footer>
				<p style={{ textAlign: "center" }}>Copyright &copy; 2021 Sognare Hair Boutique, Inc. All Rights Reserved. <a href='https://www.fleurtechnologies.com' style={{ textDecoration: 'none', color: 'rgb(131, 51, 171)' }}> | Powered by Fleur Technologies </a></p>
			</footer>
        </Container>
    );
}

export default Home;
